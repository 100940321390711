import Banner from "@/components/Cliente/Banner/Banner.vue";
import { required } from "vuelidate/lib/validators";
import mSwitch from "@/components/Switch/Switch.vue";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

export default {
	components: {
		Banner,
        mSwitch
	},
	data() {
	
		return {
			d:"",
			e1: 1,
			dialog: false,
			blLoading: true,
			modalInfoAdicional: "",
			arPerfil: [],
			arPerguntas: null,
			contador: 0,
			strTitulo: "<span class='nome'>Check List</span>",
			valid: true,
			obForm: {
				id: 0,
			}
		};

	},

	validations: {
		obForm: {
			id: {
				required
			},

		}
    },

	created() {
		this.listarPerfis()

	},


	methods: {
		listarPerfis() {
			this.$root.$api.get("checklist/listarPerfis").then(response => {
				this.arPerfil = response.retorno
			});
		},

		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}

			if (this.obForm.id == "") {
				this.blErro = true;
			}

		},

		eventoInformacaoAdicional(informacao) {
			this.modalInfoAdicional = informacao
			this.dialog = true
		},

		submit() {
			this.$v.obForm.$touch();
			this.validarFormulario();

			if (this.blErro) {
				return false;
			}

			this.$root.$api.post('checklist/obterPorPerfil', {
				perfis: this.obForm.id
			}).then(
				response => {
					this.arPerguntas = response.retorno
					this.blLoading = false
				}
			)
			this.e1 = 2
		},

		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
        },
        
        gerarPDF() {

			var quotes = document.getElementById('pdf');

			html2canvas(quotes).then(function (canvas) {
				var pdf = new jsPDF('p', 'pt', 'a4');	

				for (var i = 0; i <= quotes.clientHeight/980; i++) {

					var srcImg  = canvas;
					var sX      = 0;
					var sY      = 1120*i; 
					var sWidth  = 900;
					var sHeight = 1120;
					var dX      = 0;
					var dY      = 0;
					var dWidth  = 900;
					var dHeight = 1120;

					let onePageCanvas = document.createElement("canvas");
                	onePageCanvas.setAttribute('width', 900);
                	onePageCanvas.setAttribute('height', 1120);
					var ctx = onePageCanvas.getContext('2d');
					ctx.drawImage(srcImg,sX,sY,sWidth,sHeight,dX,dY,dWidth,dHeight);
					var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

					var width  = onePageCanvas.width;
					var height = onePageCanvas.clientHeight;

					if (i > 0) {
						pdf.addPage(595, 842);
					}
					//! now we declare that we're working on that page
					pdf.setPage(i+1);
					//! now we add content to that page!
					pdf.addImage(canvasDataURL, 'PNG', 20, 20, (width*.64), (height*.71));

				}
				pdf.save('checklist.pdf');
        	});
		},
		
	},

	computed: {
		respondidas() {
			let arRespondidas = this.arPerguntas.filter(obPergunta => {
				return obPergunta.aberto != null
			})

			return arRespondidas.length;
		}
	},

};
