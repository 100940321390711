export default {
	props: ['value'],
	data() {
		return {

		}
	},
	created() {
		console.log(this.$props.value);
	},
	methods: {
		verifica() {
			console.log(this.$props.value);
			this.$emit('input', true);
		},
		changeValue(value, force) {
			if (this.$props.value == null || force) {
				this.$emit('input', value);
			} else {
				this.$emit('input', !this.$props.value);
			}
		}
	},
}